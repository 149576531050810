.App {
  text-align: center;  
}

/* * {
  outline: 1px solid red;
  outline-offset: -1px;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: white;
  text-decoration: none;
}

a:visited {
  color: rgb(0, 229, 255);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: white;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.author-institution-card {
  margin-top: 40px;
  margin-left: 2px;
}

.submit-form-textbox {
  max-width: 50%; /* Set your desired width */
}

.submit-button-container {
  flex: 1 1 100%; /* Make the button container take full width on small screens */
  text-align: left; /* Center the button */
  /* margin-top: 10px; */
  button {
    margin-left: 10px;
    width: 50%;
  }
}

.preview-image {
  width: 50px;
  height: auto;
  font-family: "Roboto", sans-serif;
}

.preview-image-container {
  font-family: "Roboto", sans-serifw
}

.user-info {
    position: fixed;
    top: 41px;
    right: 0;
    background-color: rgba(117, 112, 112, 0.365);
    color: white;
    padding: 5px;
    max-width: 100px;
    min-width: 100px;
    border-radius: 0px 0px 0px 8px;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
}

.user-info.hidden {
    transform: translateY(-100%);
    border-radius: 0px 0px 0px 0px;
}

.navigation {
    transition: transform 0.3s ease-in-out;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.navigation::-webkit-scrollbar {
    display: none; /* WebKit */
}

.navigation.user-hidden {
    transform: translateX(-110px);
}

.hide-button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #06146cdc;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 2px solid #ececec71;
  /* border-color: #221d1d78; */
  button {
    border: none;
    border-radius: 5px;
  }
}

.response-info-main {
  /* display: flex; */
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  padding-left: 2px;
  text-decoration: none;
  font-size: 1.3em;
  line-height: 1.1em;
  /* border: 1px solid #ddd; */
}

.response-info-main table {
  width: 100%;
}

.response-info-main-table {
  /* border-collapse: collapse; */
  /* font-size: 0.9em;
  /* border: 1px solid #1613c2; */
  border-radius: 8px; 
} 

.response-info-main-table-single {
  width: 100%;
  /* border-collapse: collapse; */
  /* font-size: 1.2em; */
  /* border: 1px solid #400fbb; */
  border-radius: 8px;
} 

.response-info-main-table tr {
  width: 100%; 
  border-collapse: collapse; 
  /* white-space: pre-wrap; */
  overflow: hidden;
   /* font-size: 0.5em; */
   /* border: 1px solid #671ad3; */
} 

.response-info-single {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  font-size:1.2em;
  width: 100%;
  padding-top: 2px;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  justify-content: flex-start;
  padding-left: 2px;
  text-decoration: none;
  /* line-height: 0.7em; */
}

.response-info-filtered {
  /* display: flex; */
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  padding-left: 3px;
  text-decoration: none;
  font-size:1.1em;
  line-height: 1.2em;
}

.responses {
    display: grid;
    /* align-items: center; */
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    padding: 1px;
    margin-bottom: 40px;
}

.responses-filtered {
    display: grid;
    /* align-items: center; */
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    /* padding: 2px; */
    margin-top: 39px;
    font-size: calc(14px + 2vmin);
    margin-bottom: 40px;
}

.response-card {
    border: 2px solid #0e0d0d;
    /* padding: 1px;  */
    /* margin: 0 auto;  */
    text-align: left;
    /* width: 99%; */
    box-sizing: border-box;
    font-size: 0.4em; /* Further reduce text size */
    border-radius: 8px; /* Add this line to round corners */
    position: relative; 
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    /* justify-content: space-between; Add this line */
    height: 100%; /* Ensure the card takes full height */
    /* align-items: flex-start;  */
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
}

.response-card-single {
  border: 1px solid #ccc;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 39px;
  border: 2px solid #0e0d0d;
  /* margin-left: 2px; */
  /* margin-right: 2px;   */
}

.large-response-card img {
  /* max-width: 1000px; */
  /* max-height: 300px; */
  /* object-fit: cover; */
  align-self: center;
  border-radius: 8px;
}

.response-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 200px;
    /* min-height: 200px; */
    vertical-align: middle;
    margin: auto; 
    pre {
      cursor: url('/public/listening.png'), auto;
    }
}

.response-text-single {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* max-height: 200px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  /* word-wrap: break-word; */
  max-width: 80%;
  margin: 0 auto;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}

.long-text-single {
  font-size: 0.8em; /* Smaller font size */
  /* white-space: pre-wrap;  */
  word-wrap: break-word; /* Break long words */
  overflow: hidden; 
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
  text-align: center;
  align-self: center;
  align-items: center;
  max-height: 100%;
  max-width: 80%;
  margin: 0 auto;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}

.response-text-filtered {
  font-size: 1.1em;
  /* white-space: pre-line;  */
  word-wrap: break-word; 
  /* overflow: scroll;  */
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: center;
  align-self: center;
  align-items: center;
  max-width: 80%;
  /* width: 50%; */
  /* min-width: 0.1%; */
  margin: 0 auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}


.response-card.news { 
  background-color: rgb(44, 139, 199); 

  a {
    color: rgb(11, 69, 25);
    text-decoration: none;
  }
  a:visited {
      color: rgb(37, 31, 34);
  }

  .response-text {
    max-width: 90%;
    margin: 0 auto;
    white-space: no-wrap;
    /* width: 0.1%; */
    padding-left: 10px;
    min-height: 200px;
    /* min-height: 0.1%; */
    vertical-align: middle;
    font-size: 1.3em;
  }

  .uploaded_image {
    max-height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }

  .response-text pre {
    /* min-height: 200px; */
    vertical-align: middle;

  }

  .response-text-news {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally if needed */
    max-height: 200px;
    margin: 0 auto;
    white-space: nowrap;
    padding-left: 10px;
    vertical-align: middle;
    pre {
      font-family: "Satisfy", serif;
    }
  }

  
}
.response-card-single.news {
  background-color: rgb(44, 139, 199); 
  text-decoration: none;
  a {
    color: rgb(11, 69, 25);
    text-decoration: none;
  }
  a:visited {
      color: rgb(37, 31, 34);
  }

  .response-text-news {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally if needed */
    max-height: 200px;
    margin: 0 auto;
    white-space: nowrap;
    padding-left: 10px;
    vertical-align: middle;
    pre {
      font-family: "Satisfy", serif;
    }
  }


}
.response-text-filtered-news  {
    max-width: 90%;
    margin: 0 auto;
    white-space: no-wrap;
    /* width: 0.1%; */
    padding-left: 10px;
    min-height: 200px;
    max-height: 200px;
    /* min-height: 0.1%; */
    vertical-align: middle;
    pre {
      cursor: url('/public/listening.png'), auto;
    }
  
}


.response-card.thing { 
  background-color: rgb(51, 201, 51); 

  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }
  a:visited {
      color: rgba(77, 17, 51, 0.844);
  }
  .response-text {
    /* max-width: 85%; */
    margin: 0 auto;
    max-height: 200px;
    min-height: 200px;
  }

  .uploaded_image {
    max-height: 250px;
    max-width: 50%;
    width: auto;
    height: auto;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;
    /* overflow-y: hidden; */
  }

  .response-text-thing pre {
    font-family: "Merriweather", serif;
    font-size: 1.1em;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;
    max-height: 200px;
    /* min-height: 200px; */
  }
}
.response-card-single.thing { 
  background-color: rgb(51, 201, 51); 

  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }
  a:visited {
    color: rgba(77, 17, 51, 0.844);
}
  .response-text {
    max-width: 85%;
    margin: 0 auto;
  }

  .uploaded_image {
    max-height: 50%;
    max-width: 50%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
  }

  pre {
    font-family: "Merriweather", serif;
  }

  .response-info-main-table {
    font-size: 0.7em;
    line-height: 0.9em;
  }
}
.response-text-filtered-thing  {
  /* max-width: 80%; */
  margin: 0 auto;
  min-height: 200px;
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: scroll;
  pre {
    min-height: 200px;
    max-height: 200px;
    cursor: url('/public/listening.png'), auto;
  }
  
}


.response-card.story { 
  background-color: yellow; 
  color: #000;
  .response-text {
    max-width: 80%;
    margin: 0 auto;
  }

  a {
    color: rgb(7, 18, 83);
    text-decoration: none;
  }

  a:visited {
      color: rgba(5, 151, 22, 0.866);
  }

  .uploaded_image {
    max-height: 50%;
    max-width: 50%;
    margin: 0 auto;
    overflow: hidden;
  }

  pre {
    font-family: "Quicksand", sans-serif;
    font-size: 1.2em;
    font-weight: 600;
  }
}
.response-card-single.story { 
  background-color: yellow; 

  .response-text {
    max-width: 80%;
    margin: 0 auto;
  }

  a {
    color: rgb(7, 18, 83);
    text-decoration: none;
  }

  a:visited {
      color: rgba(5, 151, 22, 0.866);
  }

  .uploaded_image {
    max-height: 50%;
    max-width: 50%;
    margin: 0 auto;
    overflow: hidden;
  }

  pre {
    font-family: "Quicksand", sans-serif;
  }
}
.response-text-filtered-story {
  /* max-width: 80%; */
  margin: 0 auto;
  min-height: 200px;
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: scroll;
  max-width: 80%;
  font-size: 1.0em;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}


.response-card.eli5 { 
  background-color: lightgreen; 
  color: black;
  .response-text {
    /* max-width: 80%; */
    margin: 0 auto;
}
  .uploaded_image {
    max-height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .response-text-eli5 {
    min-height: 200px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
  }

  .response-text-eli5 pre {
    margin: 0 auto;
    /* max-width: 400px; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* max-height: 200px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
    max-width: 80%;
    margin: 0 auto;
    white-space: pre-wrap;
    font-family: "Schoolbell", serif;
    font-weight: 600;
    font-size: 1.2em;
  }

}
.response-card-single.eli5 { 
  background-color: lightgreen; 

  .response-text {
    max-width: 80%;
    margin: 0 auto;
}
  .uploaded_image {
    max-height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .response-text-eli5 pre {
    margin: 0 auto;
    /* max-width: 400px; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* max-height: 200px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
    /* max-width: 80%; */
    margin: 0 auto;
    white-space: pre-wrap;
    font-family: "Schoolbell", serif;
  }

}
.response-text-filtered-eli5  {
  /* max-width: 80%; */
  margin: 0 auto;
  min-height: 200px;
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: scroll;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}

.response-card.lols { 
  background-color: rgb(249, 151, 199); 
  color: black;
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .uploaded_image {
    max-height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }

  .response-text {
    max-width: 90%;
    margin: 0 auto;
    min-height: 200px;
    pre {
      max-width: 100%;
      margin: 0 auto;
      /* font-size: 1.1em; */
    }
    pre {
      font-family: "Indie Flower", serif;
      font-size: 1.4em;
      font-weight: 600;
    }
  }

  .response-text-lols {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally if needed */
    max-height: 200px;
    margin: 0 auto;
    white-space: nowrap;
    padding-left: 10px;
    vertical-align: middle;
  }

}
.response-card-single.lols { 
  background-color: rgb(249, 151, 199); 
  color: black;
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .uploaded_image {
    max-height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }

  pre {
    font-family: "Indie Flower", serif;
  }
}
.response-text-filtered-lols {
  /* font-size: 1.6em; */
  font-family: 'Indie Flower', cursive;
  max-height: 200px;
  min-height: 200px;
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}



.response-card.crypto { 
  background-color: #f7931a; 
  color: black;
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(72, 2, 42, 0.844);
  }

  .response-text {
    max-width: 80%;
    margin: 0 auto;
    font-size: 0.9em;
  }

  .uploaded_image {
    max-width: 85%;
    height: auto;
    margin: 0 auto;
    overflow-y: auto;
    /* object-fit: co ver; */
    /* overflow: e; */
  }

  .uploaded-image-image img {
    font-size: 0.8em;
    align-self: center;
    align-items: center;
    max-width: 75%;
    margin: 0 auto;
    /* max-width: 500px;  */
    /* max-height: 75%;   */
    height: auto;
    overflow: hidden;
    border-radius: 8px;
  }

  .response-text-filtered pre {
    font-size: 1.1em;
  }

  pre {
    font-family: "IBM Plex Sans", sans-serif;
  }

}
.response-card-single.crypto { 
  background-color: #f7931a; 
  color: black; 
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .response-text {
    max-width: 80%;
    margin: 0 auto;
    font-size: 0.9em;
  }

  .uploaded_image {
    max-width: 85%;
    height: auto;
    margin: 0 auto;
    overflow-y: auto;
    /* object-fit: co ver; */
    /* overflow: e; */
  }

  .uploaded-image-image img {
    font-size: 0.8em;
    align-self: center;
    align-items: center;
    max-width: 75%;
    margin: 0 auto;
    /* max-width: 500px;  */
    /* max-height: 75%;   */
    height: auto;
    overflow: hidden;
    border-radius: 8px;
  }

  .response-text-filtered pre {
    font-size: 1.1em;
  }

  pre {
    font-family: "IBM Plex Sans", sans-serif;
  }

}
.response-text-filtered-crypto  {
  max-width: 80%;
  margin: 0 auto;
  min-height: 300px;
  max-height: 300px;
  /* overflow: scroll; */
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally if needed */
  pre {
    cursor: url('/public/listening.png'), auto;
  }
}


.response-card.emoji {
  background-color: #ee7777; 
  color: black;
  .response-text {
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
  text-overflow: ellipsis;
  }

  a {
    color: rgb(7, 17, 130);
    text-decoration: none;
  }

  a:visited {
      color: rgb(236, 209, 4);
  }

  pre {
    font-family: "Noto Color Emoji", serif;
  }

  .response-text-emoji pre {
    margin: 0 auto;
    max-width: 400px;
    /* flex-direction: column; */
    /* align-items: center; */
    /* max-height: 200px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
    max-width: 80%;
    margin: 0 auto;
    white-space: pre-wrap;
  }

  .response-text-emoji{
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally if needed */
    max-height: 200px;
    min-height: 200px;
    margin: 0 auto;
    white-space: nowrap;
    padding-left: 10px;
    vertical-align: middle;
  }

}
.response-card-single.emoji {
  background-color: #ee7777; 
  color: black;
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  pre {
    font-family: "Noto Color Emoji", serif;
  }
}


.response-card.geo { 
  background-color: rgb(0, 16, 191); 
  color: black;
  a {
    color: rgb(31, 196, 22);
    text-decoration: none;
  }

  a:visited {
      color: rgba(11, 250, 7, 0.844);
  }

  .uploaded_image {
    max-height: 75%;
    max-width: 75%;
    margin: 0 auto;
    overflow: hidden;
  }

  .response-text-geo pre {
    margin: 0 auto;
    text-align: center;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    display: inline-block;
    box-shadow: var(--hover-box-shadow);  
  } 

   .response-text-geo:hover {
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out; /* Smooth transition for transform and shadow */
    transform: scale(1.4); 
    box-shadow: var(--hover-box-shadow); 
   }   

  .response-text-geo {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally if needed */
    max-height: 200px;
    min-height: 200px;
    margin: 0 auto;
    white-space: nowrap;
    /* padding-left: 10px; */
    /* vertical-align: middle; */

  }


  pre {
    font-family: "Libre Baskerville", serif;
    font-size: 1.5em;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
  }

}
.response-card-single.geo { 
  background-color: rgb(0, 16, 191); 
  color: black;
  a {
    color: rgb(17, 31, 176);
    text-decoration: none;
  }

  a:visited {
      color: rgba(255, 0, 144, 0.844);
  }

  .uploaded_image {
    max-height: 50%;
    max-width: 75%;
    margin: 0 auto;
    overflow: hidden;
  }

  pre {
    font-family: "Libre Baskerville", serif;
  }
}



.response-card img {
    max-width: 99%; 
    /* max-height: 250px;  */
    /* min-height: 200px; */
    object-fit: cover;
    align-self: center; /* Center the image */
    border-radius: 8px;
    /* padding: 2px; */
}


.response-card pre {
    background-color: #2272FF; /* Neon blue background */
    color: #cbdd45; /* Black text color */
    border: 2px outset #1D1D1D; /* Outset border */
    padding: 4px; /* Reduce padding */
    margin: 0; /* Remove margin */
    border-radius: 8px; /* Rounded corners */
    text-align: left; /* Ensure text elements are left-justified */
    /* font-family: "Jersey 10", sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 1.7em;
    align-self: center;
    text-align: left;
    max-width: 100%;
    max-height: 20%; /* Limit the height */
    overflow: auto; /* Hide overflow text */
}

.long-text {
  font-size: 0.8em; /* Smaller font size */
  white-space: pre-wrap; /* Enable word wrap */
  word-wrap: break-word; /* Break long words */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
  text-align: center;
  align-self: center;
  align-items: center;
}

.long-text-filtered {
  font-size: 0.8em; /* Smaller font size */
  white-space: pre-wrap; 
  word-wrap: break-word; /* Break long words */
  overflow: scroll; 
  text-align: center;
  align-self: center;
  align-items: center;
  max-height: 200px;
  max-width: 80%;
}

.text{
    /* width: auto; */
    float:left;
    /* width:20%; */
    /* margin-left:10px; */
  }

.up-button {
  position: absolute;
  top: 75px;
  left: 8px;
  cursor: n-resize;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 18px solid rgb(60, 116, 8);
  padding-inline: 0px;
  background-color: #a9a9a900;
  border-radius: 10px;
  border-top: 0px;
  transition: 0.2s all ease-in-out;
}

.up-button:hover {
  background-color: #04AA6D; /* Green */
  transform: scale(2.0);
  color: white;
}

.down-button {
  position: absolute;
  top: 115px;
  left: 8px;
  cursor: s-resize;
  /* height: 60px;
  width: 20px; */
  transition-duration: 0.4s;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 18px solid rgb(188, 8, 8);
  padding-inline: 0px;
  background-color: #a9a9a900;
  border-radius: 10px;
  border-bottom: 0px;
  transition: 0.2s all ease-in-out;

}

.down-button:hover {
    background-color: #c03c3c; /* Green */
    color: white;
    transform: scale(2.0);
  }

.uploaded_image {
    text-align: center;
    /* width: 100%; */
    height: auto;
    /* max-width: 250px; */
    /* max-height: 200px; */
    align-self: center;
    border-radius: 8px;
    margin: 0 auto;
}

.uploaded-image-url img {
  font-size: 0.8em;
  align-self: center;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  max-width: 100px; 
  max-height: 200px;  
  overflow: hidden;
}
.uploaded-image-url {
  /* font-size: 0.8em; */
  align-self: center;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  max-width: 75px; 
  max-height: 200px;  
  overflow: hidden;
}

.uploaded-image-image img {
  font-size: 0.8em;
  align-self: center;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
  /* max-width: 500px;  */
  max-height: 400px;  
  overflow: hidden;
  border-radius: 8px;
}

.uploaded-image-image-single img {
  align-self: center;
  align-items: center;
  max-width: 50%;
  margin: 0 auto;
  /* max-width: 500px;  */
  max-height: 33%;  
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid #1D1D1D;
}

.uploaded-image-image-filtered img {
  align-self: center;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
  /* max-width: 500px;  */
  max-height: 60%;  
  overflow: hidden;
  border-radius: 8px;
}

.generated-image {
  /* max-width: 100%; */
  /* max-height: 300px; */
  object-fit: cover;
  align-self: center;
  text-align: center;
  border-radius: 8px;
}

.generated-image img {
  max-width: 100%;
  /* max-height: 300px; */
  object-fit: cover;
  align-self: center;
  text-align: center;
  border-radius: 8px;
  /* padding: 2px; */
}

.generated-image-single {
  /* max-width: 100%; */
  /* max-height: 300px; */
  object-fit: cover;
  align-self: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 8px;
}

.generated-image-single img {
  border-radius: 8px;
  margin: 0 auto;
  align-self: center;
  border: 2px solid #1D1D1D;
}

.response-text-single pre {
  font-size: 2.2em;
  background-color: #2272FF; 
  color: #cbdd45; /* Black text color */
  border: 2px outset #1D1D1D; 
  padding: 8px;
  border-radius: 8px; /* Rounded corners */
  text-align: left; /* Ensure text elements are left-justified */
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  font-style: normal;
  align-self: center;
  /* max-width: 85%; */
  white-space: pre-wrap;
  word-wrap:normal;
  overflow-wrap: break-word;
  /* overflow: hidden; */
}

.stop-button {
  position: fixed;
  bottom: 30px;
  left: 110px;
  padding: 5px;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgba(208, 134, 22, 0.936);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-family: 'Great Vibes';
  font-size: 1.3em;
  border: 5px solid rgb(208, 134, 22);
  border-style: outset;
}

.stop-button-single {
  position: fixed;
  bottom: 30px;
  left: 10px;
  padding: 5px;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgba(208, 134, 22, 0.936);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-family: 'Great Vibes';
  font-size: 2.5em;
  border: 5px solid rgb(208, 134, 22);
  border-style: outset;
}

.stop-button-filtered {
  position: fixed;
  bottom: 30px;
  left: 10px;
  padding: 5px;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgba(208, 134, 22, 0.936);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-family: 'Great Vibes';
  font-size: 2.5em;
  border: 5px solid rgb(208, 134, 22);
  border-style: outset;
}

.pause-resume-button {
  position: fixed;
  bottom: 30px;
  left: 220px;
  padding: 5px;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgba(208, 134, 22, 0.936);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-family: 'Great Vibes';
  font-size: 1.3em;
  border: 5px solid rgb(208, 134, 22);
  border-style: outset;
}

.speed-control {
  position: fixed;
  bottom: 210px;
  left: 110px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.speed-control label {
  margin-right: 10px;
  font-family: 'Jersey 10', sans-serif;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgb(255, 255, 255);
  border-radius: 5px 5px 5px 0px;
  padding: 2px;

}

.speed-display {
  position: fixed;
  bottom: 150px;
  left: 470px;
  margin-left: 10px;
  font-size: 1.5em;
  font-family: 'Jersey 10', sans-serif;
  background-color: rgba(49, 204, 22, 0.5);
  color: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  padding: 2px;

}

.speed-slider {
  width: 100%;
  /* height: 10px; */
  /* background-color: #930b0b3f; */
  border-radius: 5px;
  border: 2px solid #1d1d1d71;
  background: rgba(145, 23, 23, 0.16);
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  appearance: none; /* Remove default styling for other browsers */
}

.speed-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  appearance: none; /* Remove default styling for other browsers */
  width: 20px;
  height: 20px;
  background: #ff0000; 
  border-radius: 50%;
  box-shadow: inset -5px -5px 5px rgba(0,0,0,.5);
}

.speed-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff0000;
  cursor: pointer;
  border-radius: 50%;
}

.speed-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 20px;
  background: rgba(49, 204, 22, 0.5); /* Change track color */
  border-radius: 5px;
}

.speed-slider::-moz-range-track {
  width: 100%;
  height: 20px;
  background: rgba(49, 204, 22, 0.5); /* Change track color */
  border-radius: 5px;
}

.progress-bar {
  position: fixed;
  left: 110px;
  bottom: 130px;
  width: 50%;
  height: 47px;
  background-color: #dddddd3f;
  border-radius: 5px;
  /* overflow: hidden; */
  margin-bottom: 10px;
  border: 2px solid #1d1d1d71;
}

.progress {
  height: 100%;
  background-color: rgba(70, 94, 229, 0.456);
  width: 0;
}

.progress-icon {
  position: absolute;
  top: -26px; 
  transform: translateX(-50%);
  cursor: pointer;
  user-select: none;
  z-index: 100;
  font-size: 1.4em; 
  filter: drop-shadow(0 0 10px #000000);
}

.toggle-button-container {
  position: fixed;
  top: 39px;
  right: 0px;
  text-align: center;
  text-align: center;
  /* background-color: white; */
  z-index: 1000;
  /* padding: 10px 20px; */
  display: inline-block;
  button {
    font-size: 0.9em;
    border-radius: 5px;
  }
}

.walter-cronkite-table-wrapper-main.show,
.uploaded_image.show {
    display: block;
    max-height: 1000px; /* Adjust as needed */
    transition: max-height 0.5s ease-in-out;
}

.walter-cronkite-table-wrapper-main.hide,
.uploaded_image.hide {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.walter-cronkite-table-wrapper-filtered.show,
.uploaded_image.show {
    display: block;
    max-height: 1000px; /* Adjust as needed */
    transition: max-height 0.5s ease-in-out;
}

.walter-cronkite-table-wrapper-filtered.hide,
.uploaded_image.hide {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.walter-cronkite-table {
  width: 100%;
  /* border-collapse: collapse; */
  border-radius: 1px;
  a:hover {
    background-color: #ffffff;
    border-radius: 8px;
    color: #01152b;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 1px 0px 1px 0px;
    /* border: 1px solid #01152b; */
  }
}

.walter-cronkite-table-wrapper {
  /* overflow: scroll; */
  /* max-height: 300px; */
}

.walter-cronkite-table-wrapper-main {
  /* overflow: scroll; */
  /* max-width: 100%; */
  max-height: 400px;
  overflow-x: hidden;
  /* font-size: 0.9em; */
  margin-left: 2px;
}

.walter-cronkite-table-wrapper-filtered {
  /* overflow: scroll; */
  max-height: 400px;
  font-size: 1.0em;
  max-width: 98%;
  overflow-x: hidden;
  margin-left: 10px;
  /* margin-right: 10px; */
  /* padding-bottom: 12px; */
}

.walter-cronkite-table-wrapper-single {
  /* overflow: scroll; */
  /* max-height: 400px; */
  font-size: 1.8em;
  max-width: 80%;
  overflow-x: hidden;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 12px;
  margin: 0 auto;
}

.walter-cronkite-table img {
  max-height: 400px;
  max-width: 50px;
}

.walter-cronkite-table td {
  border: 1px solid hsl(240, 78%, 36%);
  padding: 2px;
  border-radius: 5px;
}

.walter-cronkite-ellipsis-text {
  white-space: nowrap;
  /* white-space: pre-wrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30px; 
  min-width: 0.1%;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  vertical-align: top;

}

.expand-button {
  cursor: zoom-in;
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.2em;
}

.unexpand-button {
  cursor: zoom-out;
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.2em;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  padding-left: 5px;
  height: 40px;
  /* margin-top: 50px; */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: 2.3em; */
}

.created-by {
  float: left;
  text-align: left;
  font-size: 0.3em;
  font-family: "Rock Salt", cursive;
}

.enter-code {
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
}

.code-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  color: white;
  z-index: 1000;
  width: 90%; /* Adjust width for better responsiveness */
  max-width: 400px; /* Ensure it doesn't get too wide */
}

.code-form-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.footer-pagination {
  margin: 0 auto;
  /* font-size: 2.4em; */
}

.pagination {
  button {
    font-size: 1em;
    border-radius: 10px;
  }
}

@media (min-width: 2868px) {
  .responses {
    grid-template-columns: repeat(5, 1fr); /* Change to 2 cards per row for ipad */
    /* gap: 10px; */
  }

  .responses-filtered {
    grid-template-columns: repeat(5, 1fr); /* Change to 2 cards per row for ipad */
    /* gap: 10px; */
  }

  .response-info-main-table {
    font-size: 0.9em;
  }

}

@media (max-width: 2268px) {
  .responses {
      grid-template-columns: repeat(3, 1fr); /* Change to 2 cards per row for ipad */
  }

  .responses-filtered {
    grid-template-columns: repeat(3, 1fr); /* Change to 2 cards per row */
  }

  .response-info-main {
    font-size: 1.1em;
    /* line-height: 0.6em; */
    /* border: 1px solid #9d1212; */
  }

  .response-card img {
    max-width: 100%;
  }

  .response-card.plant {
    font-size: .3em;
  }

  .response-info-filtered {
    /* display: flex; */
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    padding-left: 3px;
    text-decoration: none;
    font-size:1.0em;
    line-height: 1.2em;
  }

  .response-info-main-table-single {
    max-width: 99%;
  }

  .response-info-filtered-table {
    max-width: 98%;
  }

  .response-text-filtered-lols pre {
    font-size: 1.7em;
    font-family: 'Indie Flower', cursive;
  }

  .walter-cronkite-table-wrapper {
    /* overflow: scroll; */
    /* max-height: 400px; */
    font-size: 0.9em;
    max-width: 100%;
    overflow-x: hidden;
  }


  .stop-button {
    /* width: 100%; */
    padding: 5px;
    font-size: 1.4em;
    width: 100px;
    /* border: 5px solid rgb(208, 134, 22); */
    border: 5px solid rgb(67, 45, 11);
    border-style: outset;
    left: 20px;
    bottom: 59px;
    color: rgb(255, 255, 255);
    background-color: rgba(49, 204, 22, 0.4);

  }

  .pause-resume-button {
    position: fixed;
    bottom: 59px;
    left: 120px;
    padding: 5px;
    background-color: rgba(49, 204, 22, 0.4);
    color: rgba(88, 55, 7, 0.936);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    font-family: 'Great Vibes';
    /* font-weight: bold; */
    font-size: 1.4em;
    border: 5px solid rgb(67, 45, 11);
    border-style: outset;
    color: rgb(255, 255, 255);

  }

  .progress-bar {
    position: fixed;
    left: 20px;
    bottom: 175px;
    width: 50%;
    height: 27px;
    background-color: #dddddd3f;
    border-radius: 5px;
    /* overflow: hidden; */
    margin-bottom: 10px;
    border: 2px solid #1d1d1d71;
  }

  .progress-icon {
    position: absolute;
    top: -31px; 
    transform: translateX(-50%);
    cursor: pointer;
    user-select: none;
    z-index: 100;
    font-size: 2.3em; /* Increase size for better visibility */
    filter: drop-shadow(0 0 10px #000000);
  }

  .speed-control {
    position: fixed;
    bottom: 280px;
    left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .speed-control label {
    margin-right: 10px;
    font-size: 1.1em;
  }
  
  .speed-display {
    position: fixed;
    bottom: 236px;
    left: 10px;
    margin-left: 10px;
    font-size: 1.1em;
  }
}

@media (max-width: 1320px) and (min-width: 769px) {
    .responses {
        grid-template-columns: repeat(2, 1fr); /* Change to 2 cards per row for ipad */
    }

    .responses-filtered {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 cards per row */
    }

    .user-info {
      position: fixed;
      top: 41px; /* Adjust as needed */
      right: 0;
      background-color: rgba(117, 112, 112, 0.365);
      /* background-color: rgba(117, 112, 112, 0.365); */
      color: white;
      padding: 5px;
      max-width: 100px;
      border-radius: 0px 0px 0px 8px;
      z-index: 100;
    }

    .response-info-main-table-single {
    max-width: 99%;
    }

    .response-info-main {
      font-size: 1.1em;
      line-height: 1.0em;
      /* border: 1px solid #9d1212; */
    }
      
    .response-card pre {
      font-size:21px; /* Further reduce text size on smaller screens */
    }
    .large-response-card pre{
      font-size: 1.7em; /* Further reduce text size on smaller screens */
    }

    .response-info-news {
      .response-info-main {
        font-size: 1.2em;
      }
    }

    .response-card img {
      max-width: 100%;
      /* max-width: 400px; */
      border-radius: 8px;
      /* padding: 0px; */
      /* max-height: 80px; */
    }

    .large-response-card img {
      max-width: 100%;
      max-height: auto;
    }

    .uploaded_image {
      /* max-width: 100%; */
      /* max-width: 200px; */
      /* max-height: 100%; */
      align-self: center;
    }

    .generated-image {
      /* max-width: 1000px; */
      /* max-height: 300px; */
      object-fit: cover;
      align-self: center;
      /* border-radius: 8px; */
    }

    .response-info-main-table {
      font-size: 1em;
      /* border: 1px solid #c21313; */
      border-radius: 8px;
    } 

    .response-text-filtered-lols pre {
      font-size: 1.7em;
      font-family: 'Indie Flower', cursive;
    }

    .walter-cronkite-table-wrapper {
      /* overflow: scroll; */
      max-height: 400px;
      font-size: 0.9em;
      /* max-width: 50%; */
      overflow-x: hidden;
      /* margin-left: 20px; */
      /* margin-right: 10px; */
      /* padding-bottom: 12px; */
    }

    .footer-pagination {
      font-size: 0.9em;
    }

    .stop-button {
      position: fixed;
      bottom: 57px;
      left: 20px;
      padding: 5px;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgba(255, 255, 255, 0.936);
      border: none;
      border-radius: 15px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      font-family: 'Great Vibes';
      font-size: 1.3em;
      border: 5px solid rgb(208, 134, 22);
      border-style: outset;
    }
    
    .stop-button-single {
      position: fixed;
      bottom: 57px;
      left: 20px;
      padding: 5px;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgba(255, 255, 255, 0.936);
      border: none;
      border-radius: 15px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      font-family: 'Great Vibes';
      font-size: 2.5em;
      border: 5px solid rgb(208, 134, 22);
      border-style: outset;
    }
    
    .stop-button-filtered {
      position: fixed;
      bottom: 57px;
      left: 20px;
      padding: 5px;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgba(255, 255, 255, 0.936);
      border: none;
      border-radius: 15px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      font-family: 'Great Vibes';
      font-size: 2.5em;
      border: 5px solid rgb(208, 134, 22);
      border-style: outset;
    }
    
    .pause-resume-button {
      position: fixed;
      bottom: 57px;
      left: 120px;
      padding: 5px;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgba(255, 255, 255, 0.936);
      border: none;
      border-radius: 15px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      font-family: 'Great Vibes';
      font-size: 1.3em;
      border: 5px solid rgb(208, 134, 22);
      border-style: outset;
    }
    
    .speed-control {
      position: fixed;
      bottom: 300px;
      left: 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    
    .speed-control label {
      margin-right: 10px;
      font-family: 'Jersey 10', sans-serif;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgb(255, 255, 255);
      border-radius: 5px 5px 5px 0px;
      padding: 2px;
    
    }
    
    .speed-display {
      position: fixed;
      bottom: 240px;
      left: 10px;
      font-size: 1.5em;
      font-family: 'Jersey 10', sans-serif;
      background-color: rgba(49, 204, 22, 0.5);
      color: rgb(255, 255, 255);
      border-radius: 0px 0px 5px 5px;
      padding: 2px;
    
    }
    
    .speed-slider {
      width: 100%;
      /* height: 10px; */
      /* background-color: #930b0b3f; */
      border-radius: 5px;
      border: 2px solid #1d1d1d71;
      background: rgba(145, 23, 23, 0.16);
      -webkit-appearance: none; /* Remove default styling for WebKit browsers */
      appearance: none; /* Remove default styling for other browsers */
    }
    
    .speed-slider::-webkit-slider-thumb {
      -webkit-appearance: none; /* Remove default styling for WebKit browsers */
      appearance: none; /* Remove default styling for other browsers */
      width: 20px;
      height: 20px;
      background: #ff0000; 
      border-radius: 50%;
      box-shadow: inset -5px -5px 5px rgba(0,0,0,.5);
    }
    
    .speed-slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #ff0000;
      cursor: pointer;
      border-radius: 50%;
    }
    
    .speed-slider::-webkit-slider-runnable-track {
      width: 100%;
      height: 20px;
      background: rgba(49, 204, 22, 0.5); /* Change track color */
      border-radius: 5px;
    }
    
    .speed-slider::-moz-range-track {
      width: 100%;
      height: 20px;
      background: rgba(49, 204, 22, 0.5); /* Change track color */
      border-radius: 5px;
    }
    
    .progress-bar {
      position: fixed;
      left: 20px;
      bottom: 163px;
      width: 50%;
      height: 47px;
      background-color: #dddddd3f;
      border-radius: 5px;
      /* overflow: hidden; */
      margin-bottom: 10px;
      border: 2px solid #1d1d1d71;
    }
    
    .progress {
      height: 100%;
      background-color: rgba(70, 94, 229, 0.456);
      width: 0;
    }
    
    .progress-icon {
      position: absolute;
      top: -16px; 
      transform: translateX(-50%);
      cursor: pointer;
      user-select: none;
      z-index: 100;
      font-size: 2.0em; 
      filter: drop-shadow(0 0 10px #000000);
    }
}



@media (max-width: 768px) {

  .toggle-button-container {
    top: 29px;
    right: -20px;
    text-align: center;
    padding: 10px 20px;
    display: inline-block;
    button {
      font-size: 0.9em;
      border-radius: 5px;
    }
  }

  .submit-button-container {
    width: 100%;
    padding-top: 5px;
    button {
      width: 95%;
    }
  }

  .responses {
      grid-template-columns: repeat(1, 1fr); 
  }

  .responses-filtered {
    grid-template-columns: repeat(1, 1fr); 
  }

  body {
    overflow-x: hidden; /* Temporarily hide horizontal overflow */
  }

  .App-header {
    /* top: 20px; */
    margin-top: 39px; 
    font-size: 0.8em;
  }
  
  .response-card.news {
    .response-text {
      min-height: 0.1%;
      margin: 5px auto;
    }
  }

  .response-card.lols {
    .response-text {
      min-height: 0.1%;
      margin: 5px auto;
    }
  }

  .response-card.eli5 {
    .response-text {
      min-height: 0.1%;
      margin: 5px auto;
    }
  }

  .response-card.crypto {
    .response-text {
      min-height: 0.1%;
      margin: 5px auto;
    }
  }

  .response-info-main {
    font-size: 2.8em;
    /* line-height: 0.4em; */
    /* border: 1px solid #9d1212; */
   }  

  .response-info-filtered {
    font-size: 1.6em;
    line-height: 1.1em;
    /* border: 1px solid #9d1212; */
  }


  .response-info-single {
    font-size: 1.3em;
    line-height: 0.8em;
    /* border: 1px solid #9d1212; */
  }

  .response-info-main-table-single {
    font-size: 0.8em;
    /* border: 1px solid #9d1212; */
    /* line-height: 0.5em; */
    /* width: 94%; */
  } 

  .response-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 200px;
    vertical-align: middle;
    margin: auto; /* Center horizontally and vertically */
  }

  .response-text-news{
    pre {
      font-size: 3.5em;
      max-width: 95%;
      min-width: 0.1%;
      min-height: 0.1%;
    }
    /* transform: scale(1.5); */
    max-width: 90%;
    min-height: 0.1%;
  }

  .response-text-lols {
    pre {
      max-width: 95%;
      min-width: 0.1%;
      min-height: 0.1%;
    }
    font-size: 2.1em;
    min-height: 0.1%;
    /* max-width: 80%; */
  }

  .response-text-eli5 {
    font-size: 2.7em;
  }

  .response-text-thing {
    font-size: 2.1em;
    /* max-height: 300px; */
  }

  .response-text-geo {
    font-size: 3.5em;
  }

  .response-text-story {
    font-size: 1.7em;
  }

  .response-text-crypto {
    pre {
      font-size: 2.8em;
    }
  }

  .response-text-emoji {
    font-size: 2.1em;
  }

  .response-text-filtered-emoji {
    font-size: 1.5em;
  }
  
  .response-card-single.news pre {
    font-size: 1.7em;
  }

  .response-card-single.thing pre {
    font-size: 0.4em;
  }

  .response-card-single.story pre {
    font-size: 0.7em;
  }
 
  .response-card-single.eli5 pre {
    font-size: 0.4em;
  }

  .response-card-single.lols pre {
    font-size: 0.6em;
  }

  .response-card-single.crypto pre {
    font-size: 0.8em;
  }

  .response-card-single.emoji pre {
    font-size: 0.7em;
  }

  .response-card-single.geo pre {
    font-size: 0.4em;
  }

  .response-text-filtered-news pre {
    font-size: 2.7em;
  }

  .response-text-filtered-lols pre {
    font-size: 0.8em;
    font-family: 'Indie Flower', cursive;
  }

  .response-text-filtered-eli5  {
    /* max-width: 80%; */
    margin: 0 auto;
    min-height: 200px;
    /* max-height: 300px; */
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-y: scroll;
    font-size: 1.6em;
  }

  .response-text-filtered-geo {
    font-size: 1.8em;
  }

  .response-text-filtered-thing  {
    /* max-width: 80%; */
    margin: 0 auto;
    min-height: 200px;
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-y: scroll;
    font-size: 1.2em;
  }

  .large-response-card img {
    max-width: 99%;
    max-height: auto;
  }

  .uploaded_image {
    max-width: 100%;
    /* max-width: 200px; */
    /* max-height: 100%; */
    align-self: center;
  }

  .uploaded-image-image-filtered img {
    max-width: 66%;
    max-height: auto;
  }

  .generated-image {
    /* width: 95%; */
    /* width: 100px; */
    /* max-height: 300px; */
    object-fit: cover;
    align-self: center;
  }

  .long-text-single {
    font-size: 0.6em; /* Smaller font size */
    white-space: pre-wrap; /* Enable word wrap */
    word-wrap: break-word; /* Break long words */
    overflow: hidden; /* Hides overflow text */
    text-overflow: ellipsis; /* Adds ellipsis for overflow text */
    text-align: center;
    align-self: center;
    align-items: center;
  }

  .response-text-single pre {
    /* font-size: 1.3em; */
    background-color: #2272FF; 
    color: #cbdd45; 
    border: 2px outset #1D1D1D; 
    padding: 4px;
    border-radius: 8px; 
    text-align: left; 
    font-weight: 400;
    font-style: normal;
    align-self: center;
    /* max-width: 85%; */
    white-space: pre-wrap;
    word-wrap:normal;
    overflow-wrap: break-word;
    /* overflow: hidden; */
  }

  .walter-cronkite-table-wrapper-filtered {
    /* overflow: scroll; */
    /* max-height: 400px; */
    font-size: 0.5em;
    max-width: 98%;
    overflow-x: hidden;
    margin-left: 0px;
  }

  .walter-cronkite-table-wrapper-single {
    /* overflow: scroll; */
    /* max-height: 400px; */
    font-size: 0.3em;
    max-width: 98%;
    overflow-x: hidden;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 12px;
  }

  .walter-cronkite-table {
    /* max-width: 93%; */
    padding-left: 3px;
    /* border-collapse: collapse; */
    font-size: 2.2em;
    /* max-height: 100px; */
    /* height: 100px; */
    overflow: scroll
  }

  .walter-cronkite-table img {
    /* width: 100%; */
    /* border-collapse: collapse; */
    font-size: 1em;
  }

  .walter-cronkite-table img {
    max-height: 200px;
    max-width: 50px;
  }

  .up-button {
    position: absolute;
    top: 70px;
    left: 8px;
    cursor: n-resize;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 18px solid rgb(60, 116, 8);
    padding-inline: 0px;
    background-color: #a9a9a900;
    border-radius: 10px;
    border-top: 0px;
    transition: 0.2s all ease-in-out;
    }

    .up-button:hover {
      background-color: #04AA6D; /* Green */
      transform: scale(2.0);
      color: white;
    }
    
    .down-button {
      position: absolute;
      bottom: 158px;
      left: 8px;
      cursor: s-resize;
      /* height: 60px;
      width: 20px; */
      transition-duration: 0.4s;
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 18px solid rgb(188, 8, 8);
      padding-inline: 0px;
      background-color: #a9a9a900;
      border-radius: 10px;
      border-bottom: 0px;
      transition: 0.2s all ease-in-out;
    
    }
    
    .down-button:hover {
      background-color: #c03c3c; /* Green */
      color: white;
      transform: scale(2.0);
    }

  .stop-button {
    /* width: 100%; */
    padding: 5px;
    font-size: 3.5em;
    width: 100px;
    /* border: 5px solid rgb(208, 134, 22); */
    border: 5px solid rgb(67, 45, 11);

    border-style: outset;
    left: 20px;
    bottom: 40px;
    color: rgb(255, 255, 255);
    background-color: rgba(49, 204, 22, 0.4);

  }

  .pause-resume-button {
    position: fixed;
    bottom: 40px;
    left: 120px;
    padding: 5px;
    background-color: rgba(49, 204, 22, 0.4);
    color: rgba(88, 55, 7, 0.936);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    font-family: 'Great Vibes';
    /* font-weight: bold; */
    font-size: 3.2em;
    border: 5px solid rgb(67, 45, 11);
    border-style: outset;
    color: rgb(255, 255, 255);

  }

  .progress-bar {
    position: fixed;
    left: 20px;
    bottom: 140px;
    width: 50%;
    height: 27px;
    background-color: #dddddd3f;
    border-radius: 5px;
    /* overflow: hidden; */
    margin-bottom: 10px;
    border: 2px solid #1d1d1d71;
  }

  .progress-icon {
    position: absolute;
    top: -21px; 
    transform: translateX(-50%);
    cursor: pointer;
    user-select: none;
    z-index: 100;
    font-size: 4.3em; /* Increase size for better visibility */
    filter: drop-shadow(0 0 10px #000000);
  }

  .speed-control {
    position: fixed;
    bottom: 210px;
    left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .speed-control label {
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  .speed-display {
    position: fixed;
    bottom: 188px;
    left: 10px;
    margin-left: 10px;
    font-size: 1.5em;
  }

  .user-info {
    position: fixed;
    top: 39px; /* Adjust as needed */
    right: 0;
    background-color: rgba(117, 112, 112, 0.365);
    color: white;
    padding: 5px;
    max-width: 100px;
    border-radius: 0px 0px 0px 8px;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
  }

  .user-info.hidden {
    transform: translateY(-100%);
  }

  .navigation {
    transition: transform 0.3s ease-in-out;
  }

  .navigation.user-hidden {
    transform: translateX(-109px);
  }

  .hide-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #06146cdc;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid #ececec71;
    /* border-color: #221d1d78; */
    button {
      border: none;
      border-radius: 5px;
    }
  }

  .expand-button {
    font-size: 3.2em;
  }

  .pagination {
    button {
      font-size: 1.7em;
      border-radius: 10px;
    }
  }

  .metal.linear {
    font-size: 2.5em;
  }

  .comments-list {
    scale: 0.92;
  }
}

a {
    color: white;
    text-decoration: none;
}

a:visited {
    color: yellow;
}

.full-width-button {
    width: 80%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    text-align: center;
    font-size: 1.2em;
    cursor: pointer;
    margin-top: 2px;
    border-radius: 8px;
    align-self: center;
    font-family: 'Great Vibes';
    margin-left: 40px;
    border: 2px solid #1d1d1d71;
    transition: transform 0.6s;
    perspective: 1000px; /* Add perspective for 3D effect */
} 

.full-width-button:hover {
  transform: rotateY(180deg); /* Flip the button */
}

.full-width-button span {
  display: inline-block;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.full-width-button:hover span {
  transform: rotateY(180deg); /* Flip the text */
}

.full-width-button span::before {
  content: "kcaB"; /* Mirror version of "Back" */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back face */
  transform: rotateY(180deg); /* Flip the back face */
}

.full-width-button span::after {
  content: "Back";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back face */
}

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px 5px;
  z-index: 100;
  background-color: rgba(117, 112, 112, 0.365);
  border-radius: 0px 0px 0px 15px;
  /* font-size: 2.8em; */
}

.in-circle {
  display: block;
  background: #4444ff;
  color: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  line-height: 10px;
  text-align: center;
  font-size:0.5em;
  font-family: Verdana;
}

/* comments */

.comments-section {
  margin-top: 2px;
  /* margin-bottom: 20px; */
  padding: 10px;
  /* background-color: #f5f5f5; */
  /* width: 95vw; */
  position: relative;
  /* left: 50%;  */
  /* right: 40%;  */
  /* margin-left: -50vw; */
  /* margin-left: -48vw;
  margin-right: -49vw; */
  background: #5b5673;
  
  background: linear-gradient(
    to right,
    #93A5CE,#E4EEE9
  );
  border-radius: 8px;
  border: 2px solid #0d0c0c;
}

.comments-content {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.arena-title {
  font-family: 'Protest Guerrilla';
  font-size: 2.5em;
  color: #007bff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.comment-form {
  margin-bottom: 40px;
  z-index: 1000;
}

.comment-textarea {
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  /* box-sizing: border-box; */
  resize: vertical;
  min-height: 100px;
  font-size: 1.2rem;
}

.comment-button {
  font-size: 1.5rem;
  height: 2rem;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: 2px solid #0d0c0c;
  border-radius: 8px;
  cursor: grab;
  transition: transform 0.3s ease;
  float: right;
  font-family: 'Protest Guerrilla';
}

.comment-button:hover {
  background-color: #a8b0b8;
  color: #007bff;
  border: 2px solid #007bff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.comment-button:active {
  background-color: #007bff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  transform: translateY(4px);
}

.sorting-options {
  font-size: 1.2em;
  background-color: linear-gradient(to right, #1d4bb6,#931d31ad);
  /* color: white; */
  border: none;
  border-radius: 8px;
  cursor: row-resize;
  border: 2px solid #0d0c0c;
}

.sorting-options:hover {
  background-color: linear-gradient(to right, #1d4bb6,#931d31ad);
  color: rgb(53, 55, 130);
  box-shadow: 0 0 5px rgba(21, 92, 155, 0.46) inset;

}

.comments-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  
}

.comment-container {
  position: relative; 
  /* display: flex;  */
  flex-direction: column;
  margin-bottom: 10px;
  z-index: 1;
  background-color: #dcdcdc;
  border-radius: 8px;
}

.comment {
  flex-grow: 1;
  padding: 4px;
  background-color: white;
  border-radius: 8px;
  transition: transform 0.3s ease;
  min-width: 200px;
  /* max-width: calc(100% - 25px); */
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3),
			  -23px 0 20px -23px rgba(0, 0, 0, .8),
			  23px 0 20px -23px rgba(0, 0, 0, .8),
			  inset 0 0 40px rgba(0, 0, 0, .1);
  /* z-index: 2; */
  /* margin-top: 4px;
  margin-left: 4px; */
  /* border-width: 1px; */
  /* border-width: 4px; */
  /* border-image-slice: 1; */
}

.comment small {
  color: #777;
}

.comment-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.comment-actions button {
  margin-left: 10px;
  padding: 4px 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #0d0c0c;
  font-family: 'roboto';
}

.comment-actions button:hover {
  background-color: #e0e0e0;
}

.comment-replies {
  /* position: relative; */
  max-height: 0;
  overflow: visible;
  transition: max-height 0.5s ease-out;
  z-index: 1;
}

.comment-replies.show {
  max-height: 1000px;
}

.comment-replies.hide {
  max-height: 0;
}

.comment-replies .comment-container {
  /* margin-left: 20px; */
  margin-top: 10px;
  border-left: 2px solid #ccc;
  /* padding-left: 10px; */
}

.reply-reply-textarea {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  min-height: 100px;
  font-size: 1.2rem;
}

.reply-reply-button {
  font-size: 1.5rem;
  height: 2rem;
  border-radius: 8px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-family: 'Protest Guerrilla';
}

.reply-reply-button:hover {
  background-color: #a8b0b8;
  color: #007bff;
  border: 2px solid #007bff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.reply-form {
  position: relative;
  padding-bottom: 3rem;
}

.bias-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  background: none;
  border: none;
  width: 15px;
  font-size: 1.0em;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.bias-arrow::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
} 

.bias-arrow.left {
  left: -23px;
  cursor: w-resize;
}

.bias-arrow.left::before {
  left: 0;
  border-width: 100% 15px 100% 0;
  border-color: transparent rgba(0, 123, 255, 0.2) transparent transparent;
}

.bias-arrow.lefttriangle {
  position: absolute;
  width: 0px;
  height: 0px;
  top: 8px;
  border-style: solid;
  border-width: 40px 0px 40px 20px;
  border-color: transparent transparent transparent rgba(18, 39, 199, 0.714);
  transform: rotate(180deg);
}

.bias-arrow.right {
  right: -23px;
  cursor: e-resize;
}

.bias-arrow.right::before {
  right: 0;
  border-width: 100% 0 100% 15px;
  border-color: transparent transparent transparent rgba(0, 123, 255, 0.2);
}

.bias-arrow.righttriangle {
  position: absolute;
  width: 0px;
  height: 0px;
  top: 8px;
  border-style: solid;
  border-width: 40px 0px 40px 20px;
  border-color: transparent transparent transparent rgba(201, 38, 38, 0.714);
}

.bias-arrow:hover::before {
  border-color: transparent rgba(0, 123, 255, 0.4) transparent rgba(0, 123, 255, 0.4);
}

.quality-arrow {
  position: absolute;
  background: none;
  border: none;
  /* width: 15px; */
  font-size: 1.0em;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  left: 50%;
}

.quality-arrow.uptriangle{
  /* position: absolute; */
  /* width: 0px;
  height: 0px; */
  top: -150px;
  cursor: n-resize;
  border-style: solid;
  border-width: 140px 0px 140px 20px;
  border-color: transparent transparent transparent rgba(7, 113, 19, 0.498);
  transform: translateX(-50%) rotate(270deg);
}

.quality-arrow.downtriangle{
  /* position: absolute; */
  /* width: 0px;
  height: 0px; */
  bottom: -150px;
  cursor: s-resize;
  border-style: solid;
  border-width: 140px 0px 140px 20px;
  border-color: transparent transparent transparent rgba(208, 92, 38, 0.673);
  transform: translateX(-50%) rotate(90deg);
}

.comment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratings {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #666;
  margin-top: 1px;
}

.emotion-picker {
  position: relative;
  width: 80px;
  height: 80px;
}

.emotion-picker button {
  transition: all 1.3s ease;
}

.emotion-picker button:hover {
  transform: scale(1.2) rotate(${index * 45}deg) translate(30px) rotate(-${index * 45}deg);
}

/* flipswitch */

.flipswitch {
  position: relative;
  width: 65px;
  margin: 0 auto;
  margin-top: 10px;
}
.flipswitch input[type=checkbox] {
  display: none;
}
.flipswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 8px;
}
.flipswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.flipswitch-inner:before, .flipswitch-inner:after {
  float: left;
  width: 50%;
  height: 23px;
  padding: 0;
  line-height: 23px;
  font-size: 16px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.flipswitch-inner:before {
  content: "on";
  padding-left: 10px;
  background-color: #256799;
  color: #FFFFFF;
}
.flipswitch-inner:after {
  content: "off";
  padding-right: 10px;
  background-color: #EBEBEB;
  color: #888888;
  text-align: right;
}
.flipswitch-switch {
  width: 34px;
  margin: -5.5px;
  background: #FFFFFF;
  border: 1px solid #999999;
  border-radius: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 39px;
  transition: all 0.3s ease-in 0s;
}
.flipswitch-cb:checked + .flipswitch-label .flipswitch-inner {
  margin-left: 0;
}
.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  right: 0;
}

/* shiny balls  */

.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(circle at 50% 120%, #9f818146, #46434390 80%, #53525298 100%);
  }
  .ball:before {
  content: " ";
  position: absolute;
  background: radial-gradient(circle at 50% 120%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  filter: blur(5px);
  z-index: 2;
  }
  .ball:after {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
  transform: translateX(-18px) translateY(-19px) skewX(-20deg);
  filter: blur(5px);
}

/* Metallic Comment and other */

.metal {
  position: relative;
  /* margin: 20px auto; */
  bottom: 5px;
  outline: none;
  padding: 5px;
  font-family: "Titillium Web", sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: hsla(0,0%,20%,1);
  text-shadow: hsla(0,0%,40%,.5) 0 -1px 0, hsla(0,0%,100%,.6) 0 2px 1px;
  background-color: hsl(0,0%,90%);
  /* cursor: context-menu; */
  box-shadow: inset hsla(0,0%,15%,  1) 0  0px 0px 2px, 
    inset hsla(0,0%,15%, .8) 0 -1px 5px 4px, 
    inset hsla(0,0%,0%, .25) 0 -1px 0px 7px, /* bottom SD */
    inset hsla(0,0%,100%,.7) 0  2px 1px 7px, /* top HL */
    
    hsla(0,0%, 0%,.15) 0 -4px 5px 3px,
    hsla(0,0%,100%,.25) 0  3px 4px 2px; 
  
  transition: color .2s;
  /* a {
    cursor: context-menu;
  } */
}

.metal.linear {
  /* font-size: 1.5em; */
  height: 20px;
  border-radius: .5em;
  /* cursor: context-menu; */
  background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
    -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),
    
    linear-gradient(180deg, hsl(0,0%,78%)  0%, 
    hsl(0,0%,90%) 47%, 
    hsl(0,0%,78%) 53%,
    hsl(0,0%,70%)100%);

}

.metal:hover {
  color: hsl(124, 94%, 50%);
  /* background-color: hsl(0, 97%, 47%); */
}

.metal.linear:hover {
  color: hsl(124, 94%, 50%);
}


.custom-loader {
  width: 100px;
  height: 100px;
  background:
    radial-gradient(farthest-side,rgba(10, 106, 202, 0.841) 90%,#0000) center/32px 32px,
    radial-gradient(farthest-side,#00E83E   90%,#0000) bottom/24px 24px;
  background-repeat: no-repeat;
  animation: s7 2s infinite cubic-bezier(.36,.11,.89,.32);
  position: relative;
}
.custom-loader::before {    
  content:"";
  position: absolute;
  width:16px;
  height:16px;
  inset:auto 0 32px;
  margin: auto;
  background:#3F34D5;
  border-radius: 50%;
  transform-origin: 50% calc(100% + 20px);
  animation:inherit;
  animation-duration: 1s;
}

@keyframes s7 {
  100%{transform: rotate(1turn)}
}


/* font stuff */

.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.titillium-web-extralight-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.titillium-web-light-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.titillium-web-semibold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.titillium-web-bold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}

.jersey-10-regular {
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.emotion-picker-container {
  position: absolute;
  width: 50px;
  height: 50px;
  transition: opacity 0.3s ease-in-out;
  transform: translateX(-5px) translateY(-5px);
  font-family: 'Noto Color Emoji';
}

.emotion-button {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.emotion-button.open {
  opacity: 1;
  transform: scale(1) rotate(var(--rotation)) translate(40px) rotate(calc(-1 * var(--rotation)));
}

.emotion-button:hover {
  transform: scale(1.2) rotate(var(--rotation)) translate(40px) rotate(calc(-1 * var(--rotation)));
}

.auth-options {
    display: flex;
    gap: 20px;
    margin-left: auto;
    margin-right: 20px;
}

.login-options {
    cursor: pointer;
    color: #61dafb;
}

.login-options-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    width: 90%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.login-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: opacity 0.2s;
}

.login-button:hover {
    opacity: 0.9;
}

.login-button img {
    width: 20px;
    height: 20px;
}

.login-button.google {
    background-color: white;
    color: #444;
}

.login-button.twitter {
    background-color: #000;
    color: white;
}

.login-button.apple {
    background-color: #000;
    color: white;
}

.email-form-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  width: 90%;
  max-width: 300px;
}

.email-form-modal h3 {
  color: white;
  margin-bottom: 20px;
  text-align: center;
}

.email-form-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.email-form-modal button {
  width: 100%;
  padding: 10px;
  background-color: #61dafb;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.email-form-modal .switch-form {
  background: none;
  color: #61dafb;
  text-decoration: underline;
  padding: 5px;
}

.error-message {
  color: #ff6b6b;
  margin-bottom: 10px;
  text-align: center;
}

.login-button.email {
  background-color: #4a4a4a;
  color: white;
}
